var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('div',[_c('label',{staticClass:"label",attrs:{"for":"email"}},[_vm._v("EMAIL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email.value),expression:"email.value"}],class:{ email: _vm.email , error: !_vm.email.valid },attrs:{"type":"email","name":"email","id":"email","required":""},domProps:{"value":(_vm.email.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.email, "value", $event.target.value)}}})]),_c('div',{staticClass:"form-position"},[_c('FormulateForm',{staticClass:"login-form",model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('h2',{staticClass:"form-title"},[_vm._v("Inregistrare Tombola")]),_c('p',[_vm._v(" Introduceti datele cerute mai jos pentru a intra in tombola si a castiga premiul cel mare ")]),_c('FormulateInput',{attrs:{"name":"surname","type":"text","label":"Nume","placeholder":"Numele","validation":"required","validation-messages":{
          required: 'Numele nu a fost completat'
      }}}),_c('FormulateInput',{attrs:{"name":"name","type":"text","label":"Prenume","placeholder":"Prenumele","validation":"required","validation-messages":{
          required: 'Prenumele nu a fost completat'
      }}}),_c('FormulateInput',{attrs:{"type":"range","min":"18","max":"90","name":"age","label":"Varsta","validation":"min:18,value","show-value":true,"value":18}}),_c('FormulateInput',{attrs:{"type":"select","options":{bucuresti: 'Bucuresti', pitesti: 'Pitesti', constanta: 'Constanta'},"name":"city","label":"Oras","placeholder":"Selectati orasul","validation":"required","validation-messages":{
          required: 'Orasul nu a fost selectat'
      }}}),_c('FormulateInput',{attrs:{"name":"address","type":"text","label":"Adresa","placeholder":"Adresa","validation":"required","validation-messages":{
          required: 'Adresa nu a fost completat'
      }}}),_c('FormulateInput',{attrs:{"name":"email","type":"email","label":"Email","placeholder":"Email","validation":"required|email","validation-messages":{
          required: 'Emailul nu a fost completat',
          email: 'Emailul nu are formatul corespunzator'
      }}}),_c('FormulateInput',{attrs:{"name":"phone","type":"text","label":"Telefon","placeholder":"Telefon","validation":"required|number","validation-messages":{
          required: 'Nr.Telefon nu a fost completat',
          number: 'Nr.Telefon poate contine doar cifre'
      }}}),_c('FormulateInput',{attrs:{"type":"select","options":{fabrica: 'Fabrica Grivita', megaimage: 'Mega Image', auchan: 'Auchan' , carrefour : 'Carrefour' , gradina: 'Gradina Dorobanti' },"name":"shop","label":"Magazinul de unde ati cumparat","placeholder":"Selectati magazinul","validation":"required","validation-messages":{
          required: 'Magazinul nu a fost selectat'
      }}}),_c('FormulateInput',{attrs:{"type":"date","name":"date","label":"Data bonului","placeholder":"Data achizitionari","validation":"required","max":"2021-04-02","validation-messages":{
          required: 'Data nu a fost completat nu a fost completat',
      }}}),_c('FormulateInput',{attrs:{"type":"text","name":"coupon","label":"Nr. Bon","validation":"required|number","validation-messages":{
          required: 'Numarul bonului nu a fost completat nu a fost completat',
          number: 'Bonul poate contine doar cifre'
      }}}),_c('FormulateInput',{staticClass:"checkbox-inline",attrs:{"type":"checkbox","label":"Sunt de acord cu regulamentul concursului","name":"terms","validation":"accepted","validation-messages":{
          accepted: 'Termeni si conditiile concursului nu au fost acceptate'
      }}}),_c('FormulateInput',{staticClass:"checkbox-inline",attrs:{"type":"checkbox","label":"Sunt de acord prelucrearea datelor personale ","name":"conditions","validation":"accepted","validation-messages":{
          accepted: 'Conditiile de prelucreare a datelor nu au fost acceptate'
      }}}),_c('FormulateInput',{attrs:{"type":"submit","label":"Trimite"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
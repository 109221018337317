<template>
  <div id="app">
    <Navbar/>
    <!-- <div id="nav">
        <router-link class="nav-element" to="/form">FORMULAR</router-link> 
        <router-link class="nav-element" to="/winners">CASTIGATORI</router-link> 
        <router-link class="logo-element" to="/"><img src="./assets/logo.png" width="200px"></router-link> 
        <router-link class="nav-element" to="/rules">REGULAMENT</router-link>
        <router-link class="nav-element" to="/contact">CONTACT</router-link>      
       <div class="nav-element nav-right"><BIconYoutube /></div>
        <div class="nav-element nav-right"><BIconInstagram /></div>
        <div class="nav-element nav-right"><BIconFacebook /></div> 
        
    </div> -->
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
// import { BIconFacebook , BIconInstagram , BIconYoutube } from 'bootstrap-vue'
// import Loading from "./components/Loading.vue"
import Navbar from "./components/Navbar.vue"
import Footer from "./components/Footer.vue"
export default {
  
  components:{
    Navbar,
    Footer,
    // BIconFacebook,
    // BIconInstagram,
    // BIconYoutube,
    // Loading
  },
  methods: {
      fetchData () {
          this.$isLoading(true) // show loading screen
          setTimeout(() => {this.$isLoading(false)} , 2000)
      }
  },
  mounted () {
      this.fetchData()
      this.$delete(this.$isLoading)
  }
}
</script>

<style>
.v-easy-dialog--backdrop-btn{
  display: none !important;
}
body {
   overflow: visible !important;
}
/* body{
  background-image: url("./assets/images/fabrica_grivita.jpg");
  background-color: black;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
} */
body{
  background-color: #C10000 !important;

}



#app {
  font-family: Bebas Neue;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  /* padding: 30px; */
  background-color: black;
  /* background-image: url("./assets/images/fabrica_grivita.jpg"); */
  /* background-size: 50% 50%; */
   /* background-position: center;  */
   /* background-repeat: no-repeat; */
  /* display: flex; */
  height:14%;
  position: fixed;
  width: 100%;
}

#nav a {
  font-weight: bold;
  color: white;
  text-align: center;
  /* width: 100%; */
}


.nav-element {
  /* padding-left: 10px; */
  font-size: 76px;
  /* border-right: 1px solid #707070; */
  padding-left: 4.9rem;
  padding-right: 4.9rem;
  /* padding: 1rem 1rem; */
}

.logo-element{
  position: relative;
}

.loading{
  background: url('./assets/buble2.gif');
  /* background-repeat: no-repeat; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  
}

@media only screen and (max-width: 400px) {
 .loading{
  background: url('./assets/buble2.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -30%;
  
}
}

</style>

<template>

<nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container">
        <button  class="navbar-toggler"  type="button" data-toggle="collapse" data-target="#navbar" @click="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class='home-hidden'>
          <router-link class="nav-link" to="/"><img class="logo-img-hidden" src="../assets/logo.png" width="100px"></router-link> 
        </div>
        <div class="navbar-collapse collapse" id="navbar" v-bind:class="{ 'show': show }">
            <ul class="navbar-nav nav-fill w-100  mr-auto">
                <li class="nav-item" @click="toggleHideNavbar()">
                   <router-link class="nav-link"  to="/form">INSCRIERE</router-link> 
                </li>
                <li class="nav-item" @click="toggleHideNavbar()">
                    <router-link class="nav-link" to="/winners">CASTIGATORI</router-link> 
                </li>
                <li class="nav-item logo-element">
                    <router-link class="nav-link" to="/"><img src="../assets/logo.png" width="100px"></router-link> 
                </li>
                <li class="nav-item" @click="toggleHideNavbar()">
                    <router-link class="nav-link"  to="/rules">REGULAMENT</router-link>
                </li>
                <li class="nav-item" @click="toggleHideNavbar()">
                    <router-link class="nav-link"  to="/contact">CONTACT</router-link>     
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>
<script>
export default {
  data () {
    return {
      show: false
    }
  },
  methods:{
    toggleNavbar() {
      this.show = !this.show
    },
    toggleHideNavbar() {
      this.show = false
    }
  }
}
</script>

<style scoped>

/* .abs-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
} */
.navbar {
    position: fixed;
    /* z-index: -1; */
    width: 100%;
    height: 9%;
    background-color: black;
}
.logo-element{
    /* position: relative; */
    padding-top: 2rem !important; 
}
.nav-item{
    padding-top: 3rem;
    font-size: 30px;
}
.nav-link{
    color: white !important;
}
.home-hidden{
    display: none ;
}
.logo-img-hidden{
  width: 100px;
}
@media only screen and (max-width: 998px) {
  .nav-item{
    padding-top: 1rem;
    font-size: 20px;
    background-color: black;
  }
  .navbar{
    height: 10%;
  }
  .home-hidden{
      display: block;
  }
  .logo-element{
    display: none;
  }
}
@media only screen and (max-width: 500px) {
 .logo-img-hidden{
  width: 50px;
  }
}
@media only screen and (max-width: 400px) {
 .logo-img-hidden{
  width: 40px;
  }
}
</style>
<template>
  <div class="home">
      <div class="home-item-top">
        <img class="img-fluid home-img" src="../assets/home_1.jpg" >
      </div>
      <div class="home-item">
        <img class="img-fluid home-img" src="../assets/home_2.png" >
      </div>
      <div class="home-item">
        <img class="img-fluid home-img" src="../assets/home_3.png" >
      </div>
      <div class="home-item">
        <img class="img-fluid home-img" src="../assets/home_4.png" >
      </div>
      
  </div>
  
</template>
<script>
export default {
  data () {
    return {
      validation: {}
    }
  }
}
</script>

<style scoped>

.home{
  background-color: #C10000 !important;
  width: 100%;
}
.home-item{
    padding-top: 1%;
}
.home-item-top{
    padding-top: 4%;
}
.home-img{
  width: 100%;
  max-width: auto;
  height: auto;
}
@media only screen and (max-width: 998px) {
  .home-item-top{
    padding-top: 11%;
  }
}
@media only screen and (max-width: 1400px) {
  .home-item-top{
    padding-top: 9%;
  }
}
@media only screen and (max-width: 500px) {
  .home-item-top{
    padding-top: 16%;
  }
}
</style>
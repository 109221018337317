


<template>
<div class='form-container'>

  
  <div>
        <label class="label" for="email">EMAIL</label>
        <input type="email" name="email" id="email" required=""
               :class="{ email , error: !email.valid }"
               v-model="email.value">
      </div>
  <div class="form-position">
  <FormulateForm
    class="login-form"
    v-model="formValues"
  >
    <h2 class="form-title">Inregistrare Tombola</h2>
    <p>
        Introduceti datele cerute mai jos pentru a intra in tombola si a castiga premiul cel mare
    </p>
    <FormulateInput
      name="surname"
      type="text"
      label="Nume"
      placeholder="Numele"
      validation="required"
      :validation-messages="{
          required: 'Numele nu a fost completat'
      }"
    />
    <FormulateInput
      name="name"
      type="text"
      label="Prenume"
      placeholder="Prenumele"
      validation="required"
      :validation-messages="{
          required: 'Prenumele nu a fost completat'
      }"
    />
    
     <FormulateInput
      type="range"
      min="18"
      max="90"
      name="age"
      label="Varsta"
      validation="min:18,value"
      :show-value="true"
      :value="18"
    />
    <FormulateInput
      type="select"
      :options="{bucuresti: 'Bucuresti', pitesti: 'Pitesti', constanta: 'Constanta'}"
      name="city"
      label="Oras"
      placeholder="Selectati orasul"
      validation="required"
      :validation-messages="{
          required: 'Orasul nu a fost selectat'
      }"
    />
    <FormulateInput
      name="address"
      type="text"
      label="Adresa"
      placeholder="Adresa"
      validation="required"
      :validation-messages="{
          required: 'Adresa nu a fost completat'
      }"
    />
    <FormulateInput
      name="email"
      type="email"
      label="Email"
      placeholder="Email"
      validation="required|email"
      :validation-messages="{
          required: 'Emailul nu a fost completat',
          email: 'Emailul nu are formatul corespunzator'
      }"
    />
    <FormulateInput
      name="phone"
      type="text"
      label="Telefon"
      placeholder="Telefon"
      validation="required|number"
      :validation-messages="{
          required: 'Nr.Telefon nu a fost completat',
          number: 'Nr.Telefon poate contine doar cifre'
      }"
    />
    <FormulateInput
      type="select"
      :options="{fabrica: 'Fabrica Grivita', megaimage: 'Mega Image', auchan: 'Auchan' , carrefour : 'Carrefour' , gradina: 'Gradina Dorobanti' }"
      name="shop"
      label="Magazinul de unde ati cumparat"
      placeholder="Selectati magazinul"
      validation="required"
      :validation-messages="{
          required: 'Magazinul nu a fost selectat'
      }"
    />
    <FormulateInput
      type="date"
      name="date"
      label="Data bonului"
      placeholder="Data achizitionari"
      validation="required"
      max='2021-04-02'
      :validation-messages="{
          required: 'Data nu a fost completat nu a fost completat',
      }"
    />
    <FormulateInput
      type="text"
      name="coupon"
      label="Nr. Bon"
      validation="required|number"
      :validation-messages="{
          required: 'Numarul bonului nu a fost completat nu a fost completat',
          number: 'Bonul poate contine doar cifre'
      }"
    />
    
    <FormulateInput
      type="checkbox"
      class="checkbox-inline"
      label="Sunt de acord cu regulamentul concursului"
      name="terms"
      validation="accepted"
      :validation-messages="{
          accepted: 'Termeni si conditiile concursului nu au fost acceptate'
      }"
    />
    <FormulateInput
      type="checkbox"
      class="checkbox-inline"
      label="Sunt de acord prelucrearea datelor personale "
      name="conditions"
      validation="accepted"
      :validation-messages="{
          accepted: 'Conditiile de prelucreare a datelor nu au fost acceptate'
      }"
    />
    <FormulateInput
      type="submit"
      label="Trimite"
    />

  </FormulateForm>
  </div>
</div>
  
</template>

<script>
export default {

  data () {
    return {
      today: new Date() , 
      formValues: {}
    }
  }
}
</script>

<style scoped>
/* .login-form {
  padding: 2em;
  border: 1px solid #a8a8a8;
  border-radius: .5em;
  max-width: 500px;
  box-sizing: border-box;
  background-color: black;
}
.form-title {
  margin-top: 0;
}
.login-form::v-deep .formulate-input .formulate-input-element {
  max-width: none;
} */
/* 
@media (min-width: 420px) {
  .double-wide {
    display: flex;
  }
  .double-wide .formulate-input {
    flex-grow: 1;
    width: calc(50% - .5em);
  }
  .double-wide .formulate-input:first-child {
    margin-right: .5em;
  }
  .double-wide .formulate-input:last-child {
    margin-left: .5em;
  }
  .form-position{
    top: 50%;
    left: 50%;
    margin-right: 5%;
    margin-top: 3%;
    color:white;
    margin-bottom: 5%;
    
  } */
  /* .checkbox-inline div.formulate-input-wrapper{
      display:flex !important;
  }

  .image-class{
    float:left;
    position: relative;

  } */

/* } */
.form-container{
   background-image: url('../assets/background.png');
}
.form-position{
    margin-right: 5%;
    margin-left: 5%;
    padding-top: 10%;
    color: black;
    background-color: white;
    border: 1px solid #a8a8a8;
    border-radius: .5em;
    /* margin-bottom: 5%; */

  }
  .text-rules p {
    margin-top: 3%;
    margin-right: 5%;
    padding-left: 5%;
  }
</style>
<template>
  <div class='winners'>
    <div class="home-winners">
         MAI AI TIMP! INSCRIE BONUL FISCAL CU ORICE PRODUS BERE GRIVITA ACHIZITIONAT DIN LOCATIILE PARTENERE PANA LA DATA DE 15.07.2021! 
    </div>
  </div>
    
</template>
<script>
export default {
  data () {
    return {
      validation: {}
    }
  }
}
</script>

<style scoped>
  .home-winners{
      padding-top: 12%;
      text-align: center;
      color: black;
      font-size: 76px;
  }
  .winners {
    background-image: url('../assets/background.png');
    height: 889px;
  }
  @media only screen and (max-width: 998px) {
  .winners {
    /* background-image: url('../assets/background.png'); */
    height: 889px;
  }
  }
  @media only screen and (max-width: 500px) {
    .home-winners {
      padding-top: 25%;
    }
    .winners{
      height: auto;
    }
  }
  @media only screen and (max-width: 400px) {
    .home-winners {
      padding-top: 25%;
    }
    .winners{
      height: auto;
    }
  }
    
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueFormulate from '@braid/vue-formulate'
import { BootstrapVue, BootstrapVueIcons , BIcon} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'typeface-bebas-neue'
import loading from 'vuejs-loading-screen'


Vue.config.productionTip = false
Vue.use(loading , {
  bg: 'white',
  icon: '../assets/buble2.gif',
  // size: 3,
  // icon_color: 'white',
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




<template>
<div class='form-container'>
<div id="app">

  <form class="vue-form" @submit.prevent="submit">

   

    <fieldset>
      <legend>Contact</legend>
      <div>
        <label class="label" for="surname">NUME</label>
        <input type="text" name="surname" id="surname" required="" v-model="surname">
      </div>
      <div>
        <label class="label" for="name">PRENUME</label>
        <input type="text" name="name" id="name" required="" v-model="name">
      </div>
      <div>
        <label class="label" for="phone">NR.TELEFON</label>
        <input type="text" name="phone" maxlength=10 id="phone" required="" v-model="phone"  @input="preventInvalidInput">
      </div>
      <div>
        <label class="label" for="email">EMAIL</label>
        <input type="email" name="email" id="email" required="" v-model="email">
      </div>
       <div>
        <label class="label" for="textarea">Mesaj</label>
        <textarea class="message" name="textarea" id="textarea" required="" v-model="message" ></textarea>
      
      </div>
      <div class="checkbox-line">
        <input type="checkbox" :value="rules_agreement" :id="rules_agreement" v-model="rules_agreement" required>
        <label class="label" for="rules_agreement">Confirm ca am citit si am inteles Informarea privind prelucrarea datelor cu caracter personal incluse <a @click="openAnex()"><b style="color:#2c3e50">in Anexa 1 a Regulamentului Campaniei</b></a></label>
      </div>
      <div class="checkbox-line">
        <input type="checkbox" :value="newsletter" :id="newsletter" v-model="newsletter">
        <label class="label" for="newsletter">Sunt de acord cu abonare la newsletter-ul BERE GRIVITA.</label>
      </div>
<!-- 
      <div>
        <h4>Framework</h4>

        <ul class="vue-form-list">
          <li>
            <input type="radio" name="radio-1" id="radio-1" value="angular" 
                   v-model="selection.framework">
            <label for="radio-1">AngularJS</label>
          </li>
          <li>
            <input type="radio" name="radio-2" id="radio-2" value="react" 
                   v-model="selection.framework">
            <label for="radio-2">ReactJS</label>
          </li>
          <li>
            <input type="radio" name="radio-3" id="radio-3" value="vue" 
                   v-model="selection.framework">
            <label for="radio-3">VueJS</label>
          </li>
        </ul>
      </div>

      <div>
        <h4>Features</h4>
        <ul class="vue-form-list">
          <li v-for="(feature, index) in features" :key="feature.id">
            <input type="checkbox" 
                   :value="feature" 
                   :id="'cb-feature-'+index" 
                   v-model="selection.features">
            <label :for="'cb-feature-'+index">{{feature}}</label>
          </li>
          <li>
            <input type="checkbox" id="checkbox-all" @click="checkAll">
            <label for="checkbox-all">Check All</label>
          </li>
        </ul>
      </div>
      <div>
        <label class="label" for="textarea">Message with Counter</label>
        <textarea class="message" name="textarea" id="textarea" required="" 
                  v-model="message.text" 
                  :maxlength="message.maxlength"></textarea>
        <span class="counter">{{ message.text.length }} / {{ message.maxlength }}</span>
      </div> -->
      <div>
        <input type="submit" value="TRIMITE" >
      </div>
    </fieldset>
  </form>

  <!-- <div class="debug">
    <pre><code>{{ $data }}</code></pre>
  </div> -->

</div>
<v-easy-dialog v-model="modal_pop">
      <div class="v-card overflow-y-auto">
          <div>{{modal.title}}</div>

          <div>
            <p >
              {{modal.message}}
            </p>
          </div>

          <div class="flex justify-end space-x-2">
            <button @click="modal_pop = false">Close</button>
          </div>
        </div>
</v-easy-dialog>
</div>
  
</template>

<script>
import VEasyDialog from 'v-easy-dialog';
import axios from "axios";
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export default {

  data () {
    return {
      
      surname: "" , 
      name: "",
      phone: "",
      email: "" , 
      message: "",
      rules_agreement: "" ,
      newsletter: "",
      submitted: false , 
      modal:{
        title:"" , 
        message: ""
      },
      modal_pop : false
    }
  },
  components : {
    VEasyDialog
  } , 
  methods: {
    // submit form handler
    submit: function() {
      this.submitted = true;
      if (this.message && this.name && this.surname && this.email && this.phone) {
          var config_insert = {
              method: 'post',
              url: 'https://tombola.beregrivita.ro/grivita/api/mail',
              headers: { 
              },
            data : JSON.stringify({
                'message': "Nume : "+this.surname+" "+this.name+" \r\n Email : "+this.email+" \r\n Telefon : "+this.phone+" \r\n Mesaj : "+this.message
              })
            };

            axios(config_insert)
            .then(response => {
              var data_insert = response.data.message ;
              console.log(data_insert);
              if(data_insert == "success"){
                  this.modal.title = "Succes";
                  this.modal.message = "Mesajul tau a fost trimis. O sa iti raspundem in cel mai scurt timp!"
                  this.modal_pop = true;
                  this.surname= "" 
                  this.name= ""
                  this.phone= ""
                  this.email= "" 
                  this.message= ""
                  this.rules_agreement= "" 
                  this.newsletter= ""
                  this.submitted= false 
                  
                  
                  // this.popupModal("Succes" , 'Felicitari! Ai fost inscris in „GRIVIȚA – BEREA PENTRU PRIMUL LOC" Pastreaza in original bonul fiscal inscris in Campanie pentru validare.' )
              }else if(data_insert == 'fail'){
                this.modal.title = "Eroare";
                this.modal.message = 'Ceva nu a mers cum trebuie va rugam sa ne contactati !'
                this.modal_pop = true;
              }else{
                this.modal.title = "Eroare";
                this.modal.message = 'Ceva nu a mers cum trebuie va rugam sa ne contactati !'
                this.modal_pop = true;
              }

            })
            .catch(function (error) {
              // this.popupModal("Eroare" , 'Ceva nu a mers cum trebuie va rogam sa ne contactati !' )
              this.modal.title = "Eroare";
              this.modal.message = 'Ceva nu a mers cum trebuie va rugam sa ne contactati !'
              this.modal_pop = true;
              console.log(error);
            });
          






        
      }
      
    },
    openAnex : function(){
        this.modal.title = "Anexa 1 ";
        this.modal.message = 'Campania promoțională „BERE GRIVIȚA - SELECȚIA 21 M.LUCESCU (denumită în continuare ”Campania”) este organizata și desfășurată de FABRICA GRIVIȚA SRL., o societate cu răspundere limitată română, cu sediul în Șoseaua Orhideelor nr.19, sector 6, București, România, având codul unic de înregistrare RO36581109, atribut fiscal RO, înregistrată la Oficiul Registrului Comerțului sub numărul J40/12816/2016, care deține conform legii calitatea de operator de date cu caracter personal și prelucrează datele cu caracter personal ale participanților la Campanie, telefon 021.796.42.29, (denumită în continuare „Organizatorul” sau “Fabrica Grivița”).';
        this.modal_pop = true;
    },
    // validate by type and value
    validate: function(type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function(value) {
      return emailRegExp.test(value);
    },
    // check or uncheck all
    checkAll: function(event) {
      this.selection.features = event.target.checked ? this.features : [];
    },
    preventInvalidInput(event) {
      let value = event.target.value;
      // Check if value is number
      let isValid = +value == +value;
        
      if (!isValid) {
        // input event is not cancellable by preventDefault() 
        // so we have to use the below approach to reset the current value to previous one
        var resetEvent = document.createEvent('Event');
        resetEvent.initEvent('input', true, true);
        event.target.value = event.target._value;
        event.target.dispatchEvent(resetEvent);
      }
    }

  },
  watch: {
    // watching nested property
    "email.value": function(value) {
      this.validate("email", value);
    }
  }

}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:300,400");

*,
*::after,
*::before {
  box-sizing: border-box;
}

.form-container {
  padding-top: 3%;
  color: #fff;
  background-image: url('../assets/background.png');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
.container {
  min-height: 100vh;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: #2c3e50;
  text-decoration: none;
}

header {
  position: relative;
  height: 150px;
  padding-top: 100px;
}

header h1 {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 300;
}

#app {
  display: flex;
}

.vue-form {
  font-size: 16px;
  width: 500px;
  padding: 15px 30px;
  border-radius: 4px;
  margin: 50px auto;
  width: 500px;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}
.vue-form div {
  /* position: relative; */
  margin: 20px 0;
}
.vue-form h4,
.vue-form .label {
  color: #94aab0;
  margin-bottom: 10px;
}
.vue-form .label {
  display: block;
}
.vue-form input,
.vue-form textarea,
.vue-form select,
.vue-form label {
  color: #2b3e51;
}
.vue-form input[type="text"],
.vue-form input[type="date"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form select,
.vue-form legend {
  display: block;
  width: 100%;
  appearance: none;
}
.vue-form input[type="text"],
.vue-form input[type="date"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form select {
  padding: 12px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type="text"]:focus,
.vue-form input[type="date"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus,
.vue-form select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
.vue-form .select {
  position: relative;
}
.vue-form .select::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 50%;
  margin-top: -8px;
  display: block;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cg%3E%0D%0A%09%3Cpolygon%20fill%3D%22%232c3e50%22%20points%3D%220.9%2C5.5%203.1%2C3.4%208%2C8.3%2012.9%2C3.4%2015.1%2C5.5%208%2C12.6%20%09%22%2F%3E%0D%0A%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E")
    no-repeat center center;
  pointer-events: none;
}
.vue-form select {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.vue-form select::-ms-expand {
  display: none;
}
.vue-form .vue-form-list {
  margin-top: 16px;
}
.vue-form .vue-form-list::after {
  clear: both;
  content: "";
  display: table;
}
.vue-form .vue-form-list li {
  display: inline-block;
  position: relative;
  user-select: none;
  margin: 0 26px 16px 0;
  float: left;
}
.vue-form input[type="radio"],
.vue-form input[type="checkbox"] {
  /* position: absolute; */
  /* left: 0;
  top: 50%; */
  /* transform: translateY(-50%); */
  /* margin: 0;
  padding: 0; */
  /* opacity: 0; */
  /* z-index: 2; */
}
.vue-form input[type="radio"] + label,
.vue-form input[type="checkbox"] + label {
  padding-left: 24px;
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="radio"] + label::after,
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  /* content: ""; */
  /* display: block; */
  /* position: absolute; */
  /* left: 0;
  top: 50%; */
  /* margin-top: -8px;
  width: 16px;
  height: 16px; */
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="radio"] + label::after {
  border-radius: 50%;
}
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.vue-form input[type="radio"] + label::after,
.vue-form input[type="checkbox"] + label::after {
  background-color: #2c3e50;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.4);
  display: none;
}
.vue-form input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="radio"]:focus + label::before,
.vue-form input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.6);
}
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::after {
  display: block;
}
.vue-form input[type="radio"]:checked + label::before,
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::before,
.vue-form input[type="checkbox"]:checked + label::after {
  animation: cd-bounce 0.3s;
}
.vue-form textarea {
  min-height: 120px;
  resize: vertical;
  overflow: auto;
}
.vue-form input[type="submit"] {
  border: none;
  background: black;
  border-radius: 0.25em;
  padding: 12px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
}
.no-touch .vue-form input[type="submit"]:hover {
  background: #42a2e1;
}
.vue-form input[type="submit"]:focus {
  outline: none;
  background: #2b3e51;
}
.vue-form input[type="submit"]:active {
  transform: scale(0.9);
}
.vue-form .error-message {
  height: 35px;
  margin: 0px;
}
.vue-form .error-message p {
  background: #e94b35;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}
.vue-form .error {
  border-color: #e94b35 !important;
}
.vue-form .counter {
  color: #2c3e50;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}

.debug {
  border-radius: 4px;
  margin: 50px auto;
  width: 500px;
  background-color: #000;
  padding: 50px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
}

.debug pre {
  color: #ffffff;
  font-size: 18px;
  line-height: 30px;
  /* font-family: "Source Code Pro", monospace; */
  font-weight: 300;
  white-space: pre-wrap;
}
.checkbox-line{
  display: flex;
}

@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes cd-bounce {
  0%,
  100% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
* {
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
}

pre {
  background-color: #e4e4e4;
  padding: 2px;
  display: inline-block;
  box-shadow: 2px 2px 5px -3px #000000;
  border-radius: 3px;
}

.demo-btn {
  color: white;
  background-color: indigo;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: opacity ease-in-out 0.15s;
}

.demo-btn:hover {
  opacity: 0.8;
}

.v-card {
  border-radius: 10px;
  background-color: white;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000);
}

.v-card > * + * {
  border-top-width: calc(1px * calc(1 - 0));
  border-bottom-width: calc(1px * 0);
}

.v-card > * {
  padding: 1rem;
}

.v-easy-dialog--backdrop-btn{
  display: none !important;
}
</style>
<template>
 <div id="container">
    <div id="contacts">
      <div id="contacts--contact">
        <p>{{ mail }}</p>
        
      </div>
      <div id="contacts--social">
         <p class="contact-p">Ne puteti gasi si pe :</p>
        <div class="social-div"><a style="color:white" href="https://www.facebook.com/beregrivita/"><BIconFacebook/></a></div>
        <div class="social-div"><a style="color:white" href="https://www.instagram.com/beregrivita/"><BIconInstagram/></a></div>
        <div class="social-div"><a style="color:white" href="https://www.fabricagrivita.ro/comanda-online/"><img src="../assets/logo2.png" width="16px"></a></div>
      </div>
    </div>
    
   </div>
</template>
<script>
import { BIconFacebook , BIconInstagram } from 'bootstrap-vue'

  export default {
    data(){
    return{
      mail : '2021 ©️ Bere Grivita. All Rights Reserved',

    }
  },
    components:{
        BIconFacebook ,
        BIconInstagram
    }
  }

</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body{
  display:flex;
  background-color:#F0F8FF;
  height:95vh;
  justify-content:center;
  align-items:flex-end;
  
}

#container{
  height: 5rem;
  width:100vw;
  color:white;
  background-color:black;
  display:flex;
  flex-direction:column;
  align-items:center;
}
#contacts{
  display:flex;
  justify-content:space-around;
  align-items:center;
  width:100%;
  height: 6rem;
}
#contacts--contact{
  text-align:right;
}
#contacts--social{
  width:20%;
  display : flex;
  justify-content : space-between;
}
#contacts--social div{
  height:30px;
  width:30px;
  /* background-color:white; */
  border-radius:50%;
}
.social-div{
    /* width: 30px; */
}
@media only screen and (max-width: 998px) {
    #contacts--social{
    width:30%;
    }
}
@media only screen and (max-width: 500px) {

}
@media only screen and (max-width: 400px) {
    .contact-p{
        font-size: 0.6rem;
    }
}
</style>
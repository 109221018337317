<template>
<div class="home-rules">
  <div class="text-rules">
  <p class="MsoNormal" align="center" style="text-align:center"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">REGULAMENTUL OFICIAL AL CAMPANIEI PROMOȚIONALE<br>
„BERE GRIVIȚA - SELECȚIA 21 M.LUCESCU”<br>
Campanie promoțională pentru consumatori<br>
Perioada campaniei: 01.06.2021 – 15.07.2021</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 1. ORGANIZATORUL ȘI REGULAMENTUL OFICIAL AL
CAMPANIEI</span></u></b></p><p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-.5in;
mso-list:l1 level2 lfo3"><!--[if !supportLists]--><span lang="RO" style="margin-left: 0.5in;font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
Arial;mso-ansi-language:RO">1.1.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><!--[endif]--><b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Campania promoțională
„BERE GRIVIȚA - SELECȚIA 21 M.LUCESCU</span></b><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp; (denumită în continuare ”<b>Campania</b>”)
este organizata și desfășurată de FABRICA GRIVIȚA SRL., o societate cu
răspundere limitată română, cu sediul în Șoseaua Orhideelor nr.19, sector 6,
București, România, având codul unic de înregistrare RO36581109, atribut fiscal
RO, înregistrată la Oficiul Registrului Comerțului sub numărul J40/12816/2016,
care deține conform legii calitatea de operator de date cu caracter personal și
prelucrează datele cu caracter personal ale participanților la Campanie,
telefon 021.796.42.29, (denumită în continuare „<b>Organizatorul</b>” sau “<b>Fabrica
Grivița</b>”).</span></p><p class="MsoListParagraphCxSpMiddle" style="margin-left: 0.5in;text-align:justify;text-indent:-.5in;
mso-list:l1 level2 lfo3"><!--[if !supportLists]--><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
Arial;mso-ansi-language:RO">1.2.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><!--[endif]--><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Campania
se va derula conform prevederilor prezentului regulament oficial, pe care
participanții la Campanie au obligația să îl respecte (denumit în continuare
"<b>Regulamentul Oficial</b>").</span></p><p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.5in;
mso-list:l1 level2 lfo3"><!--[if !supportLists]--><span lang="RO" style="margin-left: 0.5in;font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
Arial;mso-ansi-language:RO">1.3.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><!--[endif]--><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Regulamentul
Oficial al Campaniei este întocmit și va fi făcut public conform legislației
aplicabile, fiind disponibil în mod gratuit, oricărui solicitant, în oricare
dintre următoarele modalități:<br>
− accesând site-ul <b>www.tombola.beregrivita.ro</b> în secțiunea Campaniei
(accesibil și de pe mobil sau tableta) sau</span></p><p class="MsoListParagraphCxSpLast" style="text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">− printr-o solicitare scrisa trimisa în atenția
Organizatorului, la sediul acestuia din strada Șoseaua Orhideelor nr.19, sector
6, București, pe toata durata de desfășurare a Campaniei.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Potrivit
deciziei Organizatorului, Campania poate fi mediatizata in scopul
informării&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; publicului, inclusiv prin
intermediul unor materiale publicitare si/sau cu rol informativ. Informațiile
pe care astfel de materiale le conțin vor fi interpretate in conformitate si
completate cu prevederile prezentului Regulament Oficial. <b>Premiile
prezentate in imaginile folosite pe materialele de promovare a Campaniei sunt
exclusiv cu titlu de prezentare.</b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">1.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a modifica si/sau completa prezentul Regulament Oficial,
inclusiv dreptul de a suspenda, înceta, întrerupe si/sau prelungi durata, pe
parcursul desfasurarii Campaniei, oricand, prin intocmirea unor acte
aditionale, urmand ca astfel de modificari sa intre in vigoare dupa informarea
publicului asupra modificarilor intervenite, cu cel putin 24 de ore inainte ca
acestea sa devina aplicabile. Orice act aditional va fi publicat si pe site-ul
www.tombola.beregrivita.ro, in sectiunea Campaniei (accesibil si de pe mobil
sau tableta).</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">1.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Campania
este organizata intern de <b>FABRICA GRIVIȚA </b><s></s></span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 2. TEMEIUL LEGAL</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Campania
este organizata in conformitate cu prevederile Ordonantei Guvernului nr.
99/2000 privind comercializarea produselor si serviciilor pe piata.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 3. LOCUL DE DESFASURARE A CAMPANIEI SI PERIOADA
CAMPANIEI</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Campania
este organizata si se desfasoara pe intreg teritoriul Romaniei, in perioada <b>01.06.2021
(ora 00.00.00, ora Romaniei) - 15.07.2021 (ora 23.59.59, inclusiv, ora
Romaniei), </b>(denumita in continuare „<b>Perioada Campaniei</b>”), exclusiv
prin intermediul site-ului www.tombola.beregrivita.ro, in sectiunea Campaniei
(denumit in continuare „<b>Site-ul Campaniei</b>”), conform prezentului
Regulament Oficial.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">3.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Campania
se va desfasura in conformitate cu prevederile prezentului Regulament Oficial.
Prin participarea la aceasta Campanie participantii sunt de acord sa respecte
si sa se conformeze tuturor prevederilor, termenilor si conditiilor prezentului
Regulament Oficial.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">3.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a modifica perioada Campaniei, pe parcursul derularii
acesteia, dupa anuntarea in mod public a acestei modificari, conform
prevederilor din Sectiunea 1 (art. 1.5) de mai sus.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 4. PRODUSELE PARTICIPANTE</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">4.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Produsele
participante la Campanie sunt exclusiv produsele <b>Fabrica Grivița</b> marca <b>Bere
Grivița</b>, in orice fel de ambalaj distribuite de catre Organizator pe
teritoriul Romaniei si comercializate in oricare dintre următoarele lanțuri de magazine:
<b>Auchan, Mega Image, Carrefour, Cora și din magazinele Stația Rombeer, Casa
Berarilor Artizan și locațiile HoReca: Fabrica Grivița, Grădina Dorobanți, Zona
Liberă Butic, Cooperativa Bar, Grădina Monteoru</b>, care separat sau impreuna
sa cumuleze minimum 1 produs pe acelasi bon fiscal (denumite in continuare in
mod colectiv "<b>Produse participante</b>" sau individual “<b>Produs
participant</b>”). <s></s></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">4.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Campania
se desfasoara in limita stocului de Produse participante disponibil la raft in
fiecare din magazinele care le comercializeaza pe teritoriul Romaniei.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">4.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; La
incheierea Campaniei, Produsele participante isi pierd calitatea de Produs
participant la prezenta Campanie, Organizatorul nemaiavand nicio raspundere sau
obligatie fata de consumatorii care achizitioneaza asemenea produse dupa data
incetarii Campaniei, sau in afara Perioadei Campaniei, in alte zile decat cele
cuprinse in Perioada Campaniei.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 5. DREPTUL DE PARTICIPARE</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">5.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Campania
este adresata tuturor persoanelor fizice, cetateni romani sau straini, cu
domiciliul sau resedinta, chiar temporara, in Romania, cu capacitate de
exercitiu deplina, cu varsta minima de 18 ani, impliniti pana la data inceperii
Campaniei, care respecta termenii si conditiile prezentului Regulament Oficial
(denumite in continuare individual "<b>Participant</b>" si colectiv
"<b>Participanti</b>").</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">5.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; La
aceasta Campanie nu au dreptul sa participe persoanele fizice autorizate, nici
angajatii Organizatorului,<s> </s>dupa caz, respectiv membrii de familie ai
acestora (insemnand copii, parinti, sot/ sotie, frate/ sora).</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">5.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Participarea
la aceasta Campanie implica cunoasterea si acceptarea integrala, expresa si
neechivoca de catre Participanti a prezentului Regulament Oficial.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 6. PREMIILE CAMPANIEI PROMOTIONALE</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">6.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In cadrul
Campaniei sunt oferite urmatoarele premii, conform prezentului Regulament
Oficial:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">1. Premiul cel mare ce consta <b>în două învitații duble
(pentru două persoane) la un meci de fotbal ce se desfășoară în București,
alături de dl. Mircea Lucescu</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br>
• <b>Descrierea premiului</b>: Invitațiile duble la un meci de fotbal alături
de dl. Mircea Lucescu, meci de fotbal ce se desfășoară pe teritoriul României,
stabilit ulterior până la sfărșitului anului curent – 2021 (sau anul 2022 daca
masurile COVID continua si stadioanele nu primesc spectatori).</span></p><p class="MsoListParagraph" style="margin-left:25.8pt;mso-add-space:auto;
text-align:justify;text-indent:-25.8pt;mso-list:l1 level1 lfo3"><!--[if !supportLists]--><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial;mso-ansi-language:RO">2.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><!--[endif]--><b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Premiile
acordate in cadrul Campaniei sunt cele descrise in tabelul de mai jos:</span></b></p>
<table class="MsoTableGrid" border="1" cellspacing="0" cellpadding="0"  style="margin-left: 7.35pt; border: none;border-color:white;">
 <tbody><tr>
  <td width="331" valign="top" style="width:248.1pt;border:solid windowtext 1.0pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
  line-height:normal"><b><span lang="RO" style="font-size:10.0pt;font-family:
  &quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Premiu</span></b></p>
  </td>
  <td width="85" valign="top" style="width:63.8pt;border:solid windowtext 1.0pt;
  border-left:none;mso-border-left-alt:solid windowtext .5pt;mso-border-alt:
  solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
  line-height:normal"><b><span lang="RO" style="font-size:10.0pt;font-family:
  &quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Cantitate</span></b></p>
  </td>
  <td width="113" valign="top" style="width:85.05pt;border:solid windowtext 1.0pt;
  border-left:none;mso-border-left-alt:solid windowtext .5pt;mso-border-alt:
  solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
  line-height:normal"><b><span lang="RO" style="font-size:10.0pt;font-family:
  &quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Valoare unitară (RON cu TVA inclus)</span></b></p>
  </td>
  <td width="123" valign="top" style="width:92.1pt;border:solid windowtext 1.0pt;
  border-left:none;mso-border-left-alt:solid windowtext .5pt;mso-border-alt:
  solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
  line-height:normal"><b><span lang="RO" style="font-size:10.0pt;font-family:
  &quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Valoare totală (RON cu TVA inclus)</span></b></p>
  </td>
 </tr>
 <tr>
  <td width="331" valign="top" style="width:248.1pt;border:solid windowtext 1.0pt;
  border-top:none;mso-border-top-alt:solid windowtext .5pt;mso-border-alt:solid windowtext .5pt;
  padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">Invitații duble la un meci de fotbal alături de dl.
  Mircea Lucescu</span></p>
  </td>
  <td width="85" valign="top" style="width:63.8pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">2</span></p>
  </td>
  <td width="113" valign="top" style="width:85.05pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><b><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">500</span></b></p>
  </td>
  <td width="123" valign="top" style="width:92.1pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><b><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">1000</span></b></p>
  </td>
 </tr>
 <tr>
  <td width="331" valign="top" style="width:248.1pt;border:solid windowtext 1.0pt;
  border-top:none;mso-border-top-alt:solid windowtext .5pt;mso-border-alt:solid windowtext .5pt;
  padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">Minge de fotbal înscripționată BERE GRIVIȚA cu
  semnătura dl. Micea Lucescu</span></p>
  </td>
  <td width="85" valign="top" style="width:63.8pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">150</span></p>
  </td>
  <td width="113" valign="top" style="width:85.05pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><b><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">50</span></b></p>
  </td>
  <td width="123" valign="top" style="width:92.1pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><b><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">7500</span></b></p>
  </td>
 </tr>
 <tr>
  <td width="331" valign="top" style="width:248.1pt;border:solid windowtext 1.0pt;
  border-top:none;mso-border-top-alt:solid windowtext .5pt;mso-border-alt:solid windowtext .5pt;
  padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">Total</span></p>
  </td>
  <td width="85" valign="top" style="width:63.8pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">152</span></p>
  </td>
  <td width="113" valign="top" style="width:85.05pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><b><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">&nbsp;</span></b></p>
  </td>
  <td width="123" valign="top" style="width:92.1pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  mso-border-top-alt:solid windowtext .5pt;mso-border-left-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
  normal"><b><span lang="RO" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;
  mso-ansi-language:RO">8500</span></b></p>
  </td>
 </tr>
</tbody></table>

<p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">6.2.
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Valoarea comerciala totala premiilor
este <b>8500RON (TVA inclus).</b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">6.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Castigatorii
premiilor oferite in cadrul acestei Campanii nu au dreptul de a primi
contravaloarea in bani a premiilor sau schimbarea acestora cu alte bunuri sau
servicii si nici sa solicite schimbarea parametrilor/ caracteristicilor
premiilor.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">6.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Participantilor
la Campanie nu le sunt impuse niciun fel de cheltuieli directe sau indirecte
suplimentare, cu exceptia cheltuielilor normale de desfasurare a Campaniei (de
ex. costul de achizitionare a Produselor participante la Campanie).</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">6.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
Imaginile premiilor folosite pe materialele de promovare a Campaniei sunt
exclusiv cu titlu de prezentare.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 7. MECANISMUL CAMPANIEI</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pentru
participarea si inscrierea valabila in Campanie si pentru a putea intra in
posesia unuia dintre premiile acordate de catre Organizator in cadrul prezentei
Campanii, este necesara respectarea urmatorului mecanism:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">a) Sa se achizitioneze, in Perioada Campaniei, mai precis
in oricare zi din perioada 01.06.2021 (ora 00.00.00, ora Romaniei) - 15.07.2021
(ora 23.59.59, inclusiv, ora Romaniei), unul sau mai multe Produse participante
la Campanie, care separat sau impreuna sa cumuleze cantitatea minima de 1
produs, specificat pe acelasi bon fiscal. </span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">b) Sa se acceseze Site-ul Campaniei www.tombola.beregrivita.ro,
in sectiunea Campaniei, oricand in Perioada Campaniei, mai precis in oricare zi
din perioada 01.06.2021 (ora 00.00.00, ora Romaniei) - 15.07.2021 (ora
23.59.59, inclusiv, ora Romaniei).</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">c) Sa se completeze in mod corect si complet formularul
de inregistrare in Campanie, cu datele personale solicitate (nume, prenume,
numar de telefon), completarea acestor date fiind obligatorie pentru inscrierea
valabila in Campanie, si sa se inscrie cu aceasta ocazie data emiterii si
numarul bonului fiscal care atesta achizitia efectuata in Perioada Campaniei a
Produselor participante minim 1 produs, specificat pe acelasi bon fiscal,
conform Regulamentului Oficial. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Inscrierea pe Site-ul Campaniei www.tombola.beregrivita.ro,
in sectiunea Campaniei, se va face dupa cum urmeaza:</b> </span></p><p class="MsoNormal" style="margin-left:1.0in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">− Participantul trebuie sa confirme ca are varsta minima
de 18 ani, impliniti pana la data inceperii Campaniei, ca a citit, a inteles si
este de acord cu Regulamentul Oficial al Campaniei si ca a citit si a inteles
Informarea privind prelucrarea datelor cu caracter personal prin bifarea
casutei obligatorii:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b><i>„Confirm
ca am peste 18 ani, am citit, am inteles si sunt de acord cu Regulamentul&nbsp;&nbsp;&nbsp; Oficial al Campaniei promotionale”&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; „Confirm ca am citit si am inteles
Informarea privind prelucrarea datelor cu caracter personal incluse in Anexa 1
a Regulamentului Campaniei.</i></b></span></p><p class="MsoNormal" style="margin-left:1.0in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">− Participantul trebuie sa inscrie in Campanie cel putin
un bon fiscal valid, prin completarea si trimiterea, in Perioada Campaniei, a
formularului de inscriere disponibil pe Site-ul Campaniei www.tombola.beregrivita.ro,
in sectiunea Campaniei (accesibil si de pe mobil sau tableta).</span></p><p class="MsoNormal" style="margin-left:1.0in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">− Inscrierea este valabila doar daca completarea
formularului de inscriere si transmiterea datei si numarului de bon fiscal se
fac exclusiv in Perioada Campaniei 01.06.2021 (ora 00.00.00, ora Romaniei) 15.07.2021
(ora 23.59.59, inclusiv, ora Romaniei) si numai daca</span></p><p class="MsoNormal" style="margin-left:1.0in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Participantul a primit mesajul de raspuns prin
intermediul Site-ului Campaniei, care confirma inscrierea. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;− Cu un
singur bon fiscal Participantul se poate inscrie in mod valabil in Campanie o
singura data. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;−
Pentru a putea inscrie bonul fiscal pe Site-ul Campaniei www.tombola.beregrivita.ro
in sectiunea Campaniei, Participantul trebuie sa completeze urmatoarele
campuri, in formularul afisat:</span></p><p class="MsoNormal" style="margin-left:1.0in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">1. Campul <b>obligatoriu</b> corespunzator numelui&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. Campul <b>obligatoriu</b>
corespunzator prenumelui&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3. Campul <b>obligatoriu</b>
corespunzator numarului de telefon* in format national de 10 cifre, la care
poate fi contactat Participantul, in eventualitatea desemnarii acestuia drept
castigator (valabil in cadrul uneia dintre retelele de telefonie mobila Orange,
Digi, Vodafone sau Telekom); *Un Participant unic este identificat prin acelasi
numar de telefon.  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Campul <b>obligatoriu</b>
corespunzator datei emiterii si numarului de bon fiscal care&nbsp;&nbsp; atesta achizitia Produselor participante,
conform prezentului Regulament Oficial.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.
Campul <b>obligatoriu</b> corespunzator denumirii magazinului emitent al
bonului fiscal. Necompletarea oricaruia dintre campurile obligatorii sau completarea
lor cu informatii/ date inexistente sau incorecte/incomplete poate duce la
imposibilitatea de inscriere si participare in Campanie sau la imposibilitatea
contactarii in caz de castig, precum si la invalidarea participarii si
neacordarea premiului castigat.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;De
asemenea, inainte de a putea trimite formularul si de a se putea inscrie in
Campanie, Participantilor li se va solicita sa citeasca Regulamentul Oficial al
Campaniei, impreuna cu Informarea privind prelucrarea datelor cu caracter
personal, inclusa in Regulamentul Oficial ca Anexa 1 si sa confirme acceptarea
si luarea cunostinta de acestea prin bifarea casutei corespunzatoare, conform
celor de mai sus.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">d) Dupa inregistrarea in Campanie prin intermediul
Site-ului Campaniei, Participantul va primi in scurt timp un mesaj de
confirmare a inscrierii. Mesajul de tip pop up va fi afisat pe Site-ul www.tombola.beregrivita.ro,
in sectiunea Campaniei, dupa fiecare inscriere.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">e) Un Participant se poate inscrie in Campanie de mai
multe ori, insa de fiecare data trimitand datele de identificare ale unui nou
bon fiscal, care sa ateste achizitia Produsului/Produselor participante la
Campanie. <b>Un bon fiscal poate fi inregistrat o singura data in Campanie.
ASTFEL, CHIAR DACA PARTICIPANTII LA PREZENTA CAMPANIE ACHIZITIONEAZA PE BAZA
ACELUIASI BON FISCAL MAI MULTE PRODUSE PARTICIPANTE, DEPASIND CUMULAT
CANTITATEA MINIMA DE 1 produs BERE GRIVIȚA, CONFORM PREZENTULUI REGULAMENT
OFICIAL ACESTIA VOR PUTEA INSCRIE VALABIL IN CAMPANIE NUMARUL BONULUI FISCAL
RESPECTIV O SINGURA DATA, INDIFERENT DE CANTITATEA SAU NUMARUL TOTAL AL
PRODUSELOR PARTICIPANTE ACHIZITIONATE PE BAZA ACESTUIA. &nbsp;</b>In cazul in care un Participant inscrie in
Campanie aceeasi data a emiterii si acelasi numar de<b> </b>bon fiscal de mai
multe ori, acesta va primi un mesaj de atentionare asupra inscrierii
dejaefectuate, de tipul celui de mai jos:<b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><i>„Data si numarul bonului fiscal
introduse au fost deja inscrise in campania „BERE GRIVIȚA - SELECȚIA 21
M.LUCESCU”. Te rugam sa introduci o data si un numar de bon fiscal pe care<b> </b>nu
le-ai inscris pana acum in Campanie.”<b> &nbsp;&nbsp;&nbsp;&nbsp; </b></i><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
</b>In cazul inscrierii aceleiasi date si a aceluiasi numar de bon fiscal de
mai multe ori de un<b> </b>participant sau mai multi participanti, va fi luata
in considerare ca fiind valabila doar primainscriere, ordinea inscrierilor
fiind cronologica, in functie de ora receptiei in baza de date a<b> </b>Organizatorului
a inscrierilor de la participanti si nu neaparat in functie de momentul
trimiterii<b> </b>inscrierii de catre un participant, restul inscrierilor
efectuate in baza acelorasi date de identificare<b> </b>ale bonului fiscal
(data si numar de bon fiscal) fiind in mod automat descalificate. Organizatorul<b>
</b>informeaza Participantii asupra faptului ca baza de date a Organizatorului
functioneaza pe<b> </b>principiul primirii inscrierilor (receptionarii datelor
si numerelor de bon fiscal) imediat ce acestea<b> </b>au fost inscrise de catre
Participanti prin Site-ul Campaniei.<b></b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Un
Participant poate sa castige un singur premiu oferit prin tragere la sorti in
cadrul Campaniei, chiar daca este extras de mai multe ori in baza inscrierilor
sale succesive, situatie in care premiul castigat va fi cel pentru care a fost pentru
prima data extras.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Un Participant unic este identificat prin acelasi numar
de telefon. In cazul in care Organizatorul considera in mod intemeiat ca o
anumita participare nu a respectat toate conditiile Regulamentului Oficial, are
dreptul de a o anula, fara a anunta in prealabil Participantul respectiv.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>PARTICIPANTII
AU OBLIGATIA DE A PASTRA BONURILE FISCALE INSCRISE IN CAMPANIE, IN ORIGINAL,
PANA LA MOMENTUL INMANARII PREMIULUI, ACESTEA FIIND NECESARE PENTRU VALIDARE IN
CAZUL CASTIGULUI. In cazul in care Participantul declarat potential castigator
nu poate prezenta bonul fiscal inscris in Campanie (identificat prin data si
numarul inscrise), in format fizic, care sa ateste faptul ca Participantul a
achizitionat Produsul/Produsele Participante inscrise in Campanie, conform
prezentului Regulament Oficial, Participantul respectiv nu va fi validat
castigator si va pierde orice drept de a solicita acordarea premiului de catre
Organizator.</b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Nu vor fi
luate in considerare inregistrarile prin intermediul Site-ului Campaniei </span><a href="https://tombola.beregrivita.ro"><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">www.tombola.beregrivita.ro</span></a><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">, in sectiunea Campaniei, transmise in urmatoarele
conditii:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<br>
−inscrierile facute in afara Perioadei Campaniei, astfel cum a fost definita la
Sectiunea 3; −inscrierile ce nu contin data emiterii si numarul de bon fiscal
care atesta achizitia Produsului/Produselor Participante achizitonate, conform
conditiilor stipulate in prezentul Regulament, scrise corect si complet, fara
spatii si fara orice alte informatii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;−daca Participantii indica in formularul
disponibil pe Site-ul Campaniei numere de telefon nevalabile, nealocate, care
nu pot fi identificate sau nu apartin uneia dintre retelele de telefonie
indicate (Telekom, Orange, Digi sau Vodafone)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;− daca inscrierile au fost efectuate
prin frauda sau prin oricare alte modalitati si/sau echipamente electronice
si/sau software, altele decat cele legale si/sau indicate de catre Organizator,
ori au fost efectuate cu nerespectarea oricareia dintre conditiile si termenii
Regulamentului Oficial.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Organizatorul isi rezerva dreptul de a verifica
validitatea numarului de telefon folosit pentru inscrierea in Campanie, precum
si realitatea si autenticitatea datelor emiterii si a numerelor de bon fiscal
inscrise in Campanie.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Mesajele
Campaniei:&nbsp;&nbsp;&nbsp; </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pentru fiecare inscriere valabila in
Campanie Participantul va primi un mesaj de raspuns&nbsp;&nbsp;&nbsp;&nbsp; de tip pop up prin intermediul Site-ului
Campaniei www.tombola.beregrivita.ro in sectiunea Campaniei, de tipul celor de
mai jos, dupa cum urmeaza:</span></p><p class="MsoNormal" style="text-align:justify;text-indent:.5in"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">a. daca mesajul a fost trimis inainte de inceperea
Campaniei:</span></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><i><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">„Campania BERE GRIVIȚA - SELECȚIA 21 M.LUCESCU va incepe
in data de 01.06.2021. Te rugam sa revii incepand cu aceasta data.”</span></i></p><p class="MsoNormal" style="text-align:justify;text-indent:.5in"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">b. daca mesajul trimis este corect si trimis in Perioada
Campaniei:</span></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><i><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">„Felicitari! Ai fost inscris in BERE GRIVIȚA - SELECȚIA
21 M.LUCESCU Pastreaza in original bonul fiscal inscris in Campanie pentru
validare.”</span></i></p><p class="MsoNormal" style="text-align:justify;text-indent:.5in"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">c. daca mesajul nu este corect sau contine data si numar
de bon fiscal trimise anterior:</span></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><i><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">„Data si numarul bonului fiscal introduse au fost deja
inscrise in campania BERE GRIVIȚA - SELECȚIA 21 M.LUCESCU. Te rugam sa
introduci o data si un numar de bon fiscal pe care nu le-ai inscris pana acum
in Campanie. Succes!”</span></i></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">d. daca mesajul a fost trimis dupa incheierea Campaniei:</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br>
<i>BERE GRIVIȚA - SELECȚIA 21 M.LUCESCU s-a incheiat in data de 15.07.2021. Iti
multumim pentru participare!”</i></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a modifica formularea mesajelor de raspuns ale Campaniei
de mai sus, fara a fi necesara incheierea unui act aditional la prezentul
Regulament Oficial.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dupa data
limita de primire a mesajelor (15.07.2021, ora 23:59:59, inclusiv, ora
Romaniei), Organizatorul nu mai raspunde de trimiterea/ primirea mesajelor prin
intermediul site-ului www.tombola.beregrivita.ro in sectiunea Campaniei.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a intreprinde verificari in baza de date a Campaniei
pentru a preveni eventuale tentative de frauda.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
&nbsp;<b>In cazul in care unul sau mai multi
Participanti atrag atentia prin numarul de bonuri fiscale inscrise, acestia vor
putea fi contactati si li se va solicita sa prezinte in original toate bonurile
fiscale inscrise sau, aleatoriu, anumite bonuri fiscale inscrise in Campania
promotionala. In cazul in care Participantul/Participantii refuza sa prezinte
aceste bonuri fiscale in termen de 5 zile lucratoare de la solicitarea
Organizatorului <s>sau a Agentiei</s>, acestuia i se va bloca accesul in cadrul
prezentei Campanii promotionale si i se vor anula toate inscrierile anterioare.</b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7.10. &nbsp;&nbsp;&nbsp; Participantilor
la Campanie nu le sunt impuse niciun fel de cheltuieli suplimentare, cu
exceptia cheltuielilor normale de desfasurare a Campaniei (ex. achizitia de
produse participante, cheltuieli pentru acces la internet).</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 8. TRAGEREA LA SORTI</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">8.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Desemnarea
castigatorilor tuturor premiilor oferite in cadrul Campaniei se va face prin
tragere la sorti, ce va avea loc in urmatoarele conditii: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;− tragerea la sorti va avea loc in
termen de 15 zile lucratoare de la data incheierii Campaniei, la sediul Organizatorului
din București, Șoseaua Orhideelor nr 19, Sector 6 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;− la tragerea la sorti vor participa
toate inscrierile valabile efectuate de participanti in Perioada Campaniei,
conform prezentului Regulament Oficial;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;− tragerea la sorti se va realiza prin
intermediul unui sistem electronic securizat de extragere, mai exact, tragerea
la sorti presupune folosirea unui program de calculator cu distribuire si extragere
aleatorie, care va selecta castigatorii si rezervele dintr-o baza de date ce
contine toate inscrierile valide, conform prezentului Regulament Oficial;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; − tragerea la sorti va avea loc in
prezenta unei comisii formate din reprezentanti ai Organizatorului si a unui
notar public sau avocat.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">8.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pentru
fiecare dintre cele 152 premii puse in joc se vor desemna prin tragere la sorti
cate 1 (un) castigator si un numar de cate 1 (o) rezervă aferentă fiecarui
castigator in parte, pentru situatia in care castigatorii respectivi nu sunt
validati sau refuza sa primeasca premiul. In cazul in care, din orice motiv,
oricare dintre cei 152 castigatori extrasi nu poate fi validat, atribuirea
premiului respectiv se va face catre rezerve, in ordinea extragerii acestora,
conform celor de mai jos<br>
Ordinea in care se va efectua extragerea este urmatoarea:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. Castigatorii premiului cel mare, invitație
dublu la un meci de fotbal alături de dl. Mircea Lucescu</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">2. Castigatorii celor 150 premii ce constau in mingi de
fotbal inscripționate BEREGRIVIȚA, semnate de către dl. Mircea Lucescu</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">4. Cele 2 rezerve aferente premiului cel mare, si anume &nbsp;invitațiile duble la un meci de fotbal alături
de dl. Mircea Lucescu</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">5. Cele 150 rezerve aferente celor 150 premii ce constau
in &nbsp;mingi de fotbal inscripționate BERE
GRIVIȚA semnate de către dl. Mircea Lucescu , cate 1 rezervă &nbsp;pentru fiecare premiu;<br>
<!--[if !supportLineBreakNewLine]--><br>
<!--[endif]--></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">8.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Lista
castigatorilor validati ai premiilor va fi publicata pe site-ul </span><a href="http://www.tombola.beregrivita.ro/"><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">www.tombola.beregrivita.ro</span></a><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> in sectiunea Campaniei, in termen de maximum 15 zile
lucratoare de la validarea finala a acestora. In cazul in care exista premii
neacordate, acestea vor ramane in proprietatea Organizatorului.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 9. PROCEDURA DE VALIDARE A CASTIGATORILOR SI
INMANAREA PREMIILOR</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ulterior
extragerii castigatorilor Campaniei si rezervelor aferente, potrivit
mecanismului de mai sus, Organizatorul va proceda la validarea acestora, ocazie
cu care se va verifica indeplinirea conditiilor privind inscrierea
Participantilor in Campanie, precum si modul de desfasurare a acesteia.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>PENTRU
A PUTEA INTRA IN POSESIA PREMIILOR, PARTICIPANTII LA CAMPANIE TREBUIE SA
PASTREZE IN ORIGINAL SI IN STARE BUNA/NEDETERIORATA TOATE BONURILE FISCALE CARE
AU FOST INREGISTRATE IN CAMPANIE, PANA LA PRIMIREA PREMIULUI.<br>
</b>Este interzisa reproducerea si falsificarea bonurilor fiscale inscrise in
Campanie, precum si<b> </b>alterarea, in orice mod, a bonurilor originale sau a
datelor emiterii sau numerelor acestora.<b> <br>
</b>In cazul premiilor mici, castigatorii vor intra in posesia premiilor in
maxim 45 de zile de la finalizarea<b> </b>procesului de validare a acestora.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">In cazul premiului cel mare, constand în invitațiile
duble la un meci de fotbal alături de dl. Mircea Lucescu,<b> </b>castigatorul
va fi informat de catre Organizator cu privire la termenul in care acesta va
intra in<b> </b>posesia acestuia, ulterior procesului de validare. Termenul
este unul estimativ si poate fi prelungit<b> </b>automat, perioada de
intarziere generata de situația actuală a pandemiei SARS COV-2 respectiv
participarea la meciurile de fotbal a spectatorilor. Inmanarea premiilor<b> </b>Campaniei
se va face in urma identificarii participantului si presupune semnarea si
completarea<b> </b>unui proces verbal prin care castigatorul confirma receptionarea
premiului. Datele personale ce vor<b> </b>fi completate in procesul verbal de
predare-primire a premiului sunt: nume, prenume, cetatenie,<b> </b>adresa de
domiciliu, semnatura.<b></b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Castigatorii
premiilor vor fi contactati telefonic de catre &nbsp;<b>Organizator</b>, in vederea anuntarii lor,
in maxim 5 zile lucratoare de la data extragerii lor drept castigatori.<br>
Organizatorul va efectua maxim 3 apeluri in vederea instiintarii, acestea fiind
efectuate in intervalul orar 09:00 – 18:00, timp de maxim 3 zile lucratoare.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pentru ca
un Participant sa fie validat castigator al unui premiu oferit in cadrul
acestei Campanii, acesta trebuie sa indeplineasca simultan urmatoarele
conditii:<br>
</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Segoe UI Symbol&quot;,sans-serif;
mso-bidi-font-family:&quot;Segoe UI Symbol&quot;;mso-ansi-language:RO">✓</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> sa indeplineasca conditiile de participare stipulate in
in prezentul Regulament Oficial;<br>
</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Segoe UI Symbol&quot;,sans-serif;
mso-bidi-font-family:&quot;Segoe UI Symbol&quot;;mso-ansi-language:RO">✓</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> sa poata fi contactat la numarul de telefon cu care s-a
inregistrat in Campanie in termen de 5 zile lucratoare de la data desemnarii
sale ca si castigator, in intervalul orar 09:00 – 18:00;. <br>
</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Segoe UI Symbol&quot;,sans-serif;
mso-bidi-font-family:&quot;Segoe UI Symbol&quot;;mso-ansi-language:RO">✓</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> sa nu mai fi fost desemnat castigator anterior pentru un
alt premiu in cadrul acestei Campanii, chiar daca a folosit numere de telefon
diferite pentru inscrierea in Campanie;<br>
</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Segoe UI Symbol&quot;,sans-serif;
mso-bidi-font-family:&quot;Segoe UI Symbol&quot;;mso-ansi-language:RO">✓</span><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> sa transmita catre Organizator documentele si
informatiile solicitate de aceasta, in vederea intrarii in posesia premiului,
in termenul indicat de Organizator, dupa cum urmeaza: nume, prenume, varsta,
adresa de e-mail, adresa de livrare</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Datele
necesare prevalidarii vor fi completate intr-un formular ce va putea fi accesat
prin intermediul e-mail/sms. Dupa prevalidarea informatiilor transmise prin
formular, Participantul va fi informat asupra urmatoarei etape a validarii.
Daca datele inscrise sunt corecte, participantul va trebui sa incarce
documentele in maximum 3 zile lucratoare. Daca Participantul nu completeaza
formularul cu datele sale de contact sau nu transmite documentele solicitate in
termen de 3 (trei) zile lucratoare de la primirea acceptului, Participantul va
fi invalidat si se vor contacta rezervele, in ordinea desemnarii acestora.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Documentele
incarcat/e trebuie sa aiba o dimensiune de maxim 4MB si sa fie in format 'png',
'pdf', 'jpg', 'jpeg', 'gif', 'bmp'.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In ziua
intrarii efective in posesia premiului, castigatorul trebuie sa semneze un
proces verbal de predare primire a premiului.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In cazul
in care castigatorii nu pot fi contactati/refuza/se afla in incapacitate de a
intra in posesia premiului, atribuirea premiului se va face catre rezerve, in
ordinea extragerii lor, conform art. 8.2 de mai sus. Procesul de notificare si
validare pentru rezerve este cel descris anterior. Daca nici rezervele nu sunt
validate, premiul nu se acorda si va ramane in proprietatea Organizatorului.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.9 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a nu acorda integral premiile daca Participantii
desemnati castigatori nu indeplinesc conditiile de validare.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9.10. &nbsp;&nbsp;&nbsp; Lista
tuturor castigatorilor validati si a premiilor castigate de acestia va fi
afisata pe Site-ul Campaniei <b>www.tombola.beregrivita.ro</b> in sectiunea
Campaniei, in termen de maxim 15 zile lucratoare de la data validarii tuturor
castigatorilor.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 10. ERORI ALE BONURILOR FISCALE</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">10.1.&nbsp;&nbsp;&nbsp;&nbsp; Inscrierile
in Campanie sau revendicarile facute pe baza unor date si numere de bon fiscal
care sunt ilizibile, degradate, modificate, duplicate, falsificate, viciate
etc. nu vor fi luate in considerare de catre Organizator in vederea acordarii
premiilor.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">10.2. &nbsp;&nbsp;&nbsp; Organizatorul
acestei Campanii nu isi asuma nicio raspundere pentru astfel de bonuri fiscale
(ilizibile, deteriorate, falsificate, pierdute, instrainate etc.) sau care nu
corespund acestui Regulament Oficial, sau pentru orice alti factori
independenti de Organizator, care ar putea afecta dreptul unei persoane de
participare la prezenta Campanie si nu va acorda premiile pe baza unor astfel
de bonuri fiscale, care nu intrunesc toate conditiile prevazute in prezentul
Regulament Oficial. Organizatorul acestei Campanii nu isi asuma raspunderea
pentru nicio situatie care nu rezulta din culpa sa exclusiva.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 11. RASPUNDERE</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.1. &nbsp;&nbsp;&nbsp; Prin
participarea la Campanie Participantii sunt de acord si se obliga sa respecte
si sa se conformeze prezentului Regulament Oficial, precum si tuturor
cerintelor si deciziilor luate in conformitate cu Regulamentul Oficial de
Organizator in toate aspectele legate de implementarea prezentei Campanii.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.2. &nbsp;&nbsp;&nbsp; Organizatorul
nu are nicio obligatie de a&nbsp; întretine
corespondenta cu solicitantii posesori ai unor ambalaje promotionale
considerate invalide conform acestui Regulament Oficial, sau ai unor
revendicari ce apar ulterior acordarii efective a premiilor, sau dupa termenul
de revendicare a premiilor prevazut in prezentul Regulament Oficial. Raspunderea
cu privire la acordarea premiilor este limitata in conformitate cu prevederile
prezentului Regulament Oficial.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.3. &nbsp;&nbsp;&nbsp; Organizatorul
nu isi asuma raspunderea:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">▪ pentru datele si numerele de bon fiscal incomplete,
inexacte sau incorecte inscrise in Campanie, precum si pentru datele si
numerele de bon fiscal trimise inainte sau dupa Perioada Campaniei;<br>
▪ pentru neprimirea, pierderea sau primirea cu intarziere a e-mail-urilor,
respectiv neprimirea, pierderea sau primirea intarziata a SMS-urilor
/apelurilor/ e-mail-urilor trimise de catre Organizator<s> </s>&nbsp;si invers, determinate de factori independenti
de vointa sau controlul Organizatorului<s> </s>&nbsp;(cum ar fi, dar fara a se limita la,
defectiuni sau probleme tehnice ce tin de retelele de telefonie, retelele de
internet, retelele de curent electric etc);</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">▪ daca numarul de telefon declarat nu poate fi utilizat
pentru contactarea si identificarea Participantilor (numar incorect, numarul nu
este alocat, este dezactivat, este blocat, nu se afla in aria de acoperire
etc). In acest caz, Organizatorul<s>/Agentiile</s> nu il vor putea contacta pe Participant
in eventualitatea unui castig;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">▪ pentru intreruperile neanuntate ale serviciilor oferite
de catre terti - nefunctionalitati ale furnizorilor de internet,
nefunctionalitati ale furnizorilor de curent electric etc;<br>
▪ eventualele dispute legate de drepturile asupra numerelor de telefon/
adreselor de e-mail folosite la inscrierea in Campanie;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">▪ pentru pierderea si/ sau deteriorarea de catre
Participanti a bonurilor fiscale inscrise in Campanie;<br>
▪ eventualele dispute legate de dreptul de proprietate asupra bonurilor fiscale
inscrise in Campanie;<br>
▪ erorile in datele furnizate de catre Participanti, daca este cazul;
acuratetea datelor de contact nu atrage raspunderea Organizatorului, fiind in
responsabilitatea exclusiva a Participantilor. Ca atare, Organizatorul nu are
niciun fel de obligatie in cazul furnizarii de catre Participanti a unor date
eronate, care au dus la imposibilitatea inmanarii premiului sau la
imposibilitatea identificarii unui castigator;<br>
▪ imposibilitatea unui castigator de a intra in posesia premiului din motive
independente de Organizator<s> <br>
</s>▪ cazurile in care castigatorul nu respecta conditiile de validare
detaliate in prezentul Regulament Oficial;<br>
▪ intreruperile/ disfunctionalitatile furnizorului de internet sau blocarea
accesului intern datorate aglomerarii retelelor pe perioadele de trafic intens
sau altor motive independente de Organizator<s> <br>
</s>▪ derularea activitatilor de mentenanta ce nu pot fi amanate, desfasurate
de catre operatorii serviciilor de internet, cat si a serverelor ce gazduiesc
site-ul, cat si alte servicii de care functionalitatea site-ului este
dependenta si nu poate functiona, daca prin aceasta, site-ul Campaniei nu poate
fi accesat in vederea efectuarii inscrierilor in Campanie.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.4. &nbsp;&nbsp;&nbsp; Organizatorul
nu isi asuma raspunderea si nu vor fi parte in disputele referitoare la dreptul
de proprietate asupra premiilor. Aparitia sau existenta vreunei dispute sau
litigiu referitor la acordarea premiilor nu va influenta principiul potrivit
caruia Organizatorul Campaniei va acorda premiul Participantului care a
respectat prevederile prezentului Regulament Oficial si care face dovada participarii
valabile in Campanie. Din momentul primirii premiilor de catre castigatori Organizatorul
nu mai raspunde de integritatea si calitatea acestora.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.5. &nbsp;&nbsp;&nbsp; Ordinea
receptionarii inscrierilor este reprezentata de data calendaristica si ora
(minute, secunde) serverului care receptioneaza aceste mesaje, indiferent de
data si ora trimiterii efective a mesajelor/ inscrierilor de catre
Participanti.</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">11.6.
&nbsp;&nbsp;&nbsp; Dovada de expediere a inscrierilor nu
reprezinta si dovada primirii acestora de catre Organizator.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.7. &nbsp;&nbsp;&nbsp; Organizatorul
are dreptul de a invalida formularele de participare care contin informatii
false ori &nbsp;&nbsp;&nbsp;&nbsp;vadit eronate si care nu
indeplinesc conditiile de validare.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.8. &nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a verifica si monitoriza modul in care se desfasoara
inscrierile in Campanie. In cazul in care se observa tentative de frauda,
inscrierile respective vor fi anulate.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.9. &nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a exclude definitiv orice participant in Campanie care,
prin comportamentul fraudulos sau contrar bunelor moravuri, afecteaza bunul
mers al Campaniei.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.10.&nbsp;&nbsp; Organizatorul
Campaniei va acorda premii numai castigatorilor care au luat parte la Campanie
in conformitate cu prevederile prezentului Regulament Oficial si care fac
dovada inscrierii valide in cadrul Campaniei. In cazul in care Organizatorul
constata ca un castigator nu a indeplinit si/sau nu a respectat conditiile
stipulate de prezentul Regulament Oficial, Organizatorul isi rezerva dreptul de
a suspenda/anula oricand drepturile si beneficiile ce revin castigatorului,
fara ca Organizatorul sa datoreze vreo despagubire sau plata respectivului
castigator. In cazul in care Organizatorul constata asemenea situatii dupa ce
premiul a fost deja acordat, respectivul castigator va restitui Organizatorului
valoarea premiului si cheltuielile aferente suportate de Organizator in
legatura cu acesta.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">11.11.&nbsp;&nbsp; Organizatorul
este indreptatit sa ia toate masurile necesare in caz de tentativa de frauda, abuz
sau orice alte tentative care ar putea afecta imaginea Campaniei si/sau a Organizatorului<s>.</s>
Organizatorul isi rezerva dreptul de a exclude definitiv din randul
Participantilor orice persoana care, prin comportamentul fraudulos, afecteaza
bunul mers al Campaniei. Incercarea de frauda va rezulta in descalificarea
respectivului participant pentru intreaga Perioada a Campaniei si implicit
acesta nu va mai avea dreptul de a primi niciun premiu. In cazul in care sunt
identificate persoane care au influentat sau care au facilitat castigarea de
premii, Organizatorul are dreptul de a cere angajarea raspunderii respectivelor
persoane.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 12. INFORMARE PRIVIND PRELUCRAREA DATELOR CU
CARACTER PERSONAL</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">12.1. &nbsp;&nbsp;&nbsp; Informatii
privind prelucrarea datelor cu caracter personal efectuata, privind drepturile
Participantilor la Campanie sau in legatura cu desfasurarea acestei Campanii
sunt prevazute la Anexa 1 a acestui Regulament Oficial.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">12.2. &nbsp;&nbsp;&nbsp; Solicitarile/reclamatiile
privitoare la prelucrarea datelor cu caracter personal in legatura cu Campania se
pot depune conform indicatiilor din Anexa 1.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 13. TAXE SI IMPOZITE</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">13.1. &nbsp;&nbsp;&nbsp; Organizatorul
Campaniei nu este raspunzator pentru plata taxelor, impozitelor sau a altor obligatii
fiscale legate de premiile oferite, cu exceptia impozitului pe venit cu
retinere la sursa, aplicabil veniturilor individuale provenite din premii,
impozit pe care Organizatorul Campaniei este obligat sa il calculeze, sa il
retina si sa il transfere la bugetul de stat conform prevederilor Legii nr.
227/2015 privind Codul Fiscal.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">13.2 &nbsp;&nbsp;&nbsp;&nbsp; Orice alte
obligatii de natura fiscala sau de orice alta natura in legatura cu premiul
sunt in sarcina exclusiva a castigatorilor.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 14. INCETAREA CAMPANIEI INAINTE DE TERMEN</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">14.1. &nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a intrerupe sau de a suspenda oricand desfasurarea
Campaniei, pentru motive independente de vointa sa sau in cazul in care
intervin evenimente care ar ingreuna semnificativ derularea in conditii optime
a Campaniei. Organizatorul va informa in cel mai scurt timp posibil publicul cu
privire la suspendarea sau intreruperea Campaniei prin intermediul site-ului
www.tombola.beregrvita.ro, in sectiunea Campaniei.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 15. FORTA MAJORA</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">15.1. &nbsp;&nbsp;&nbsp; Forta
Majora este evenimentul imprevizibil si de neinlaturat, intervenit dupa
intrarea in vigoare a prezentului Regulament Oficial, care nu poate fi
controlat de catre Organizator si care il impiedica pe acesta sa-si
indeplineasca obligatiile asumate prin prezentul Regulament Oficial.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">15.2. &nbsp;&nbsp;&nbsp; Daca o
situatie de forta majora, inclusiv imposibilitatea Organizatorului din motive
independente de vointa sa, impiedica sau intarzie total sau partial
desfasurarea Campaniei conform Regulamentului Oficial sau continuarea
Campaniei, Organizatorul va fi exonerat de raspunderea privind indeplinirea obligatiilor
sale pentru perioada in care aceasta indeplinire va fi impiedicata sau intarziata.
Organizatorul nu isi asuma in niciun fel obligatia de a prelungi Campania cu o
perioada corespunzatoare duratei evenimentului de forta majora.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">15.3. &nbsp;&nbsp;&nbsp; Organizatorul
isi rezerva dreptul de a intrerupe sau de a suspenda oricand desfasurarea
Campaniei, pentru motive independente de vointa sa sau in cazul in care
intervin evenimente care ar ingreuna semnificativ derularea in conditii optime
a Campaniei. Organizatorul va informa in cel mai scurt timp posibil publicul cu
privire la suspendarea sau intreruperea Campaniei, prin publicare pe site-ul <b>www.tombola.beregrivita.ro</b>,
in sectiunea Campaniei sau prin alte mijloace de informare a publicului.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">SECTIUNEA 16. LITIGII</span></u></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">16.1. &nbsp;&nbsp;&nbsp; Eventualele
litigii aparute intre Organizator si Participantii la prezenta Campanie se vor
rezolva pe cale amiabila sau, in cazul in care aceasta nu va fi posibila, vor
fi solutionate de instantele judecatoresti romane competente din Bucuresti.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">16.2. &nbsp;&nbsp;&nbsp; Eventualele
reclamatii/sesizari legate de derularea Campaniei se vor putea trimite catre &nbsp;&nbsp;<b>Organizator</b> cu sediul în București,
Șoseaua Orhideelor nr. 19, sector 6, București in termen de maximum 10 (zece)
zile de la data publicarii tuturor castigatorilor validati. Dupa aceasta data Organizatorul
nu va mai lua in considerare nici o reclamatie/sesizare.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">16.3.&nbsp;&nbsp;&nbsp;&nbsp; 
Eventualele reclamatii/sesizarilor vor cuprinde in mod obligatoriu:<br>
a) numele, domiciliul/ resedinta, precum si alte date necesare pentru
identificarea si contactarea contestatorului;<br>
b) prezentarea motivelor de fapt pe care se intemeiaza contestatia.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">16.4. &nbsp;&nbsp;&nbsp; Sesizarile
si reclamatiile se vor solutiona in termen de 10 (zece) zile lucratoare de la
data primirii. Prezentul Regulament Oficial a fost semnat si autentificat
intr-un exemplar original care va ramane in arhiva notarului public si in 4
duplicate astazi, __________.</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">&nbsp;</span></p><p class="MsoNormal" align="center" style="text-align:center"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">ANEXA nr. 1<br>
la REGULAMENTUL OFICIAL AL CAMPANIEI PROMOTIONALE<br>
BERE GRIVIȚA - SELECȚIA 21 M.LUCESCU<br>
Campanie promotionala pentru consumatori<br>
Perioada campaniei: 01.06.2021- 15.07.2021</span></b></p><p class="MsoNormal" align="center" style="text-align:center"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- Informare cu privire la prelucrarea datelor cu caracter
personal -</span></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:-.5in"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Date
privind operatorul de date cu caracter personal <s></s></span></b></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">In vederea desfasurarii Campaniei, datele cu caracter
personal ale Participantilor vor fi prelucrate de catre:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">FABRICA GRIVIȚA S.R.L..,</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> cu sediul in &nbsp;Șoseaua Orhideelor nr 19, sector 6, Bucuresti,
inregistrata la Registrul Comertului cu nr. J40/12816/2016, CUI RO36581109, atribut
fiscal RO, telefon 021.796.42.29, (denumita in continuare
"Organizatorul" sau "Operatorul" )</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Datele
de contact ale Operatorului pentru nelamuriri sau exercitarea drepturilor de
catre persoanele vizate cu privire la datele cu caracter personal sunt
urmatoarele:</span></p><p class="MsoNormal" style="text-align:justify"><a href="mailto:FABRICA"><b><span lang="RO" style="font-size: 10pt; line-height: 107%; font-family: Arial, sans-serif; color: windowtext;">FABRICA</span></b></a><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> GRIVIȚA</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO"> S.R.L. ,
adresa Șoseaua Orhideelor nr 19, sector 6, București, telefon: 021.796.42.29,
email: tombola@fabricagrivița.ro</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">2. Categorii de date cu caracter personal prelucrate in
cadrul Campaniei</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">In
cadrul Campaniei, Organizatorul va prelucra de la participanti urmatoarele
categorii de date cu caracter personal:<br>
• Pentru Participantii la Campanie vor fi prelucrate: nume si prenume; numar de
telefon; data sinumar bon fiscal, magazin, datele rezultate din vizitarea
site-ului Campaniei;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">•
Pentru Castigatorii Campaniei, in plus fata de cele de mai sus, vor fi
prelucrate: adresa de livrare a premiului, semnatura, confirmare cu privire la
varsta de cel putin 18 ani, adresa email, bon fiscal in intregime. </span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">3. Scopul prelucrarii</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Datele
cu caracter personal ale participantilor la Campanie vor fi prelucrate de catre
Organizator<s>,</s> in vederea:<s></s></span></p><p class="MsoListParagraphCxSpFirst" style="margin-left:.75in;mso-add-space:auto;
text-align:justify;text-indent:-.5in;mso-list:l3 level1 lfo4"><!--[if !supportLists]--><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial;mso-ansi-language:RO">(i)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><!--[endif]--><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">organizarii
si desfasurarii Campaniei;</span></p><p class="MsoListParagraphCxSpMiddle" style="margin-left:.75in;mso-add-space:
auto;text-align:justify;text-indent:-.5in;mso-list:l3 level1 lfo4"><!--[if !supportLists]--><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial;mso-ansi-language:RO">(ii)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><!--[endif]--><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">(ii)
desemnarii si validarii castigatorilor;</span></p><p class="MsoListParagraphCxSpLast" style="margin-left:.75in;mso-add-space:auto;
text-align:justify;text-indent:-.5in;mso-list:l3 level1 lfo4"><!--[if !supportLists]--><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-fareast-font-family:Arial;mso-ansi-language:RO">(iii)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><!--[endif]--><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">(iii)
atribuirii premiilor si indeplinirii obligatiilor fiscale si financiar
contabile ale Operatorului.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">4. Temeiul juridic al prelucrarii</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Datele
vor fi prelucrate astfel:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- Datele furnizate de Participanti si Castigatori (nume,
prenume, telefon, email (daca este cazul), data si nr bon fiscal, magazin,
adresa de livrare a premiului, confirmare privind varsta de cel putin 18 ani,
semnatura, bon fiscal in intregime) sunt prelucrate in temeiulexecutarii unui
contract (acceptarea termenilor si conditiilor Regulamentului Oficial si&nbsp; participarea la Campanie echivaleaza cu
executarea unui contract)</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- Datele rezultate din vizitarea site-ului Campaniei sau
din efectuarea de verificari privindvalidarea inscrierii participantilor sunt
prelucrate in temeiul interesului legitim al Organizatorului, pentru
organizarea si desfasurarea Campaniei si efectuarea procedurilor de extragere a
castigatorilor si acordare a premiilor in conditii de deplina legalitate.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- Publicarea listei cu castigatori pe site-ul www.tombola.beregrivita.ro
este efectuata in temeiul obligatiei legale a Operatorului de a publica astfel
de liste, potrivit legislatiei privind organizarea de promotii si
concursuri/loterii publicitare.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- Pastrarea datelor ulterior incheierii Campaniei si
inmanarii premiilor reprezinta o prelucrare in temeiul interesului legitim al
Operatorului, pentru a putea dovedi desfasurarea Campaniei si acordarea
premiilor, precum si in temeiul obligatiilor legale aferente legislatiei financiar-contabile
ce impune pastrarea documentelor contabile pentru o anumita perioada.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">5. Destinatarii datelor cu caracter personal</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Datele
cu caracter personal colectate in cadrul Campaniei de catre Organizator vor fi
dezvaluite<b> </b>autoritatilor, in masura in care este necesar pentru
respectarea<b> </b>obligatiilor legale de catre Organizator.<b> </b></span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">De
asemenea, datele personale vor mai putea fi dezvaluite, numai in masura in care
este necesar,<b> </b>urmatoarelor categorii de terti:<b> </b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- Societati care furnizeaza produse si servicii
Organizatorului, cum ar fi furnizori de sistemeIT si furnizorii de servicii de
asistenta aferenti, inclusiv furnizorii de servicii de<b> </b>telecomunicatii,
realizare copii de rezerva si recuperare in caz de dezastru, servicii
desecuritate informatica si alti furnizori de servicii externalizare, cum ar fi
stocare in afara<b> </b>spatiului de productie propriu si furnizori de servicii
de stocare in sistem cloud;<b></b></span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- alte entitati cum ar fi autoritati de reglementare,
contabili, auditori, avocati sau alti experti externi, in cazul in care
activitatea lor necesita aceste informatii;</span></p><p class="MsoNormal" style="text-align:justify;text-indent:.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">- curieri implicati in livrarea premiilor catre
castigatori;</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Tertii
carora le sunt transmise sau puse la dispozitie informatiile personale ale
Participantilor, in temeiul paragrafului de mai sus, sunt limitati (prin lege
si prin contract) cu privire la modul in care pot folosi aceste datele.
Organizatorul se asigura ca orice terti carora le sunt divulgate voluntar date
cu caracter personal sunt supusi obligatiilor de confidentialitate si securitate
in acord cu prezenta nota de informare si legislatia aplicabila (pentru
evitarea dubiilor, acest lucru nu se poate aplica acolo unde divulgarea nu este
decizia voluntara).</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Cu
exceptia celor detaliate mai sus, Organizatorul nu va divulga vreunui tert vreo
parte din datele personale prelucrate in legatura cu Campania, fara a anunta
persoanele vizate, sau, daca este cazul, fara a obtine in prealabil
consimtamantul acestora.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">6. Perioada de stocare a datelor cu caracter personal</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Datele
cu caracter personal ale Participantilor declarati necastigatori vor fi stocate
de catre Operator si/sau Imputerniciti timp de 90 de zile de la incheierea
Campaniei. In cazul in care sunt reclamatii sau sesizari in curs, pentru
acestea poate fi necesara prelucrarea datelor cu caracter personal pe o durata
ulterioara celor 90 de zile indicate mai sus, pana la solutionarea definitiva a
sesizarii/ reclamatiei. Dupa trecerea acestui termen, datele vor fi distruse,
acestea nefiind utilizate in niciun alt scop in afara prelucrarii aferente
Campaniei &nbsp;BERE GRIVIȚA - SELECȚIA 21
M.LUCESCU</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Datele
cu caracter personal ale castigatorilor vor fi stocate de catre Operator
conform prevederilor legale aplicabile in materie financiar-contabila,
respectiv 10 (zece) ani de la data incheierii exercitiului financiar in care a
avut loc plata impozitului din premii.</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">La
expirarea perioadei de stocare a datelor cu caracter personal, Operatorul va
sterge/ distruge aceste date de pe mijloacele de prelucrare si stocare<s>.</s></span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">7. Drepturile persoanelor vizate</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">In
vederea asigurarii unei prelucrari echitabile si transparente, Operatorul
asigura Participantilor, pe Durata Campaniei, urmatoarele drepturi:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(i)</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO"> <b><u>dreptul
de retragere a consimtamantului:</u></b> in cazul prelucrarilor de date
efectuate in baza consimtamantului, persoanele vizate pot retrage
consimtamantul in orice moment fara ca acest lucru sa afecteze prelucrarea
anterioara;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(ii)</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO"> <b><u>dreptul
de a solicita accesul la datele cu caracter personal</u></b>: persoanele vizate
pot solicita informatii legate de datele personale pe care Operatorul <s>si
Imputernicitii </s>le detine despre acestea, inclusiv informatii legate de
categoriile de date prelucrate, pentru ce suntfolosite acestea, si cui sunt
divulgate aceste date, daca este cazul. La cerere, persoanelor vizate le poate
fi oferita o copie a datelor personale prelucrate inlegatura cu Campania. Daca
sunt solicitate mai multe copii ale datelor personale, Operatorul isi rezerva
dreptul de a percepe o taxa rezonabila bazata pe costurile administrative.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(iii)</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO"> <b><u>dreptul
de a solicita rectificarea datelor cu caracter personal</u></b>: persoanele
vizate pot obtine rectificarea datelor personale pe care le Operatorul le prelucreaza
in legatura cu Campania. Operatorul depune eforturi rezonabile pentru a mentine
datele personale corecte, complete, actualizate si relevante, pe baza celor mai
recente informatii de care dispun;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(iv)</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO"> <b><u>dreptul
de a solicita stergerea datelor cu caracter personal</u></b>: persoanele vizate
pot solicita stergerea datelor personale prelucrate de Operator in legatura cu
Campania. Operatorul trebuie sa se conformeze acestei solicitari, daca:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">•Datele personale nu mai sunt necesare pentru
indeplinirea scopurilor pentru care au fost colectate;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">•Persoana vizata se opune prelucrarii din motive legate
de situatia sa particulara;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">•Datele cu caracter personal au fost prelucrate ilegal;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">•Datele personale trebuie sterse pentru respectarea unei
obligatii legale care ii revine Operatorului. cu exceptia cazului in care
datele sunt necesare:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">a)pentru exercitarea dreptului la libera exprimare si la
informare;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">b)pentru conformarea de catre Operator cu o obligatie
legala;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Ac)in scopuri de arhivare in interes public, stiintific
sau pentru studii istorice sau in scopuri statistice; sau<br>
d)pentru constatarea, exercitarea sau apararea unui drept in instanta.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(v) dreptul la restrictionarea prelucrarii</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">: persoanele vizate pot obtine restrictionarea prelucrarii
datelor personale, in cazul in care:</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">• contesta corectitudinea datelor personale, pentru
perioada de care este nevoiepentru a verifica corectitudinea,</span></p><p class="MsoNormal" style="text-align:justify;text-indent:.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">• prelucrarea este nelegala si solicita restrictionarea
prelucrarii in locul stergerii datelor personale,</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">• nu mai avem nevoie de datele personale dar persoana
vizata le solicita pentru stabilirea, exercitarea sau apararea pretentiilor
legale, sau</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">• persoana vizata are obiectii legate de prelucrare, pentru
perioada necesara pentru a verifica daca motivele intemeiate ale Operatorului prevalează.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(vi) dreptul la obiecție: persoanele vizate pot obiecta</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO"> – in orice moment la prelucrarea datelor personale din
motive legate de situația acestora particulara, cu condiția ca prelucrarea sa nu
se bazeze pe consimțământul persoanei vizate ci pe interesele legitime ale
Operatorului sau ale unei terțe parti. In acest caz, datele personale nu vor
mai fi prelucrate, cu excepția cazului in care: (i) Operatorul poate dovedi
motive legitime si imperioase care justifica prelucrarea si care prevalează
asupra intereselor, drepturilor si libertăților persoanei vizate sau (ii) sau
in cazul in care scopul este constatarea, exercitarea sau apărarea unui drept in
instanță. Persoanele vizate care obiectează la prelucrare, sunt rugate sa
specifice daca doresc, de asemenea, ca datele personale sa fie șterse.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(vii) dreptul la portabilitate a datelor</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">: persoana vizata poate solicita transferul datelor prelucrate
in temeiul consimțământului sau in executarea unui contract, către ea insasi, sau,
daca este fezabil din punct de vedere tehnic, direct către un alt operator;</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">(viii) dreptul de a depune o reclamație</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">: persoanele vizate pot depune reclamații la Autoritatea Nationala
de Supraveghere a Prelucrării Datelor cu Caracter Personal.</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:.5in"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Nume</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">:  Autoritatea Națională de Supraveghere a Prelucrării
Datelor cu Caracter Personal</span></p><p class="MsoNormal" style="margin-left:1.0in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Adresa</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">: B-dul
G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336, Bucuresti</span></p><p class="MsoNormal" style="margin-left:.5in;text-align:justify;text-indent:.5in"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Telefon</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +40.318.059.211</span></p><p class="MsoNormal" style="margin-left:1.5in;text-align:justify;text-indent:
.5in"><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">+40.318.059.212</span></p><p class="MsoNormal" style="margin-left:1.0in;text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Fax</span></b><span lang="RO" style="font-size:10.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +40.318.059.602<br>
<b>Email</b>: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; anspdcp@dataprotection.ro</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Desemnarea
câștigătorilor Campaniei se va face prin mijloace automate de prelucrare a
datelor cu caracter personal, fără interventie umana.</span></p><p class="MsoNormal" style="text-align:justify"><b><u><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">De reținut:</span></u></b></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Perioada de timp</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">: Termenul de răspuns pentru solicitarile persoanelor
vizate este de 30 de zile, ce poate fi prelungit, in cazul unor motive
specifice legate de anumite cerințe legale sau de complexitatea cererii, cu o
perioada suplimentara de 2 luni.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Restricționarea accesului</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">: In anumite situatei, accesul la toate sau o parte a
datelor personale poate fi restricționat din cauza unor cerințe legale. In
cazul unui astfel de refuz cu privire la cererea de acces, vor fi comunicate
motivele refuzului.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Imposibilitatea identificării</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">: In anumite cazuri, persoanele vizate nu pot fi identificate
in baza elementelor de identificare furnizate in cerere. In astfel de cazuri,
daca persoana nu poate fi identificata drept persoana vizata, Operatorul nu
poate da curs solicitării respectivei persoane, cu excepția cazului in care oferă
Operatorului informatii suplimentare care sa permită identificarea.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">Exercitarea drepturilor</span></b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">: Participanții isi pot exercita drepturile menționate
anterior fie printr-o cerere scrisa, datata, semnata si adresata
Organizatorului la &nbsp;Șoseaua Orhideelor nr
19, sectorul 6, București, fie prin transmiterea acesteia la adresa de e-mail
tombola@beregrivita.ro.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">8. Securitatea datelor cu caracter personal</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Organizatorul
este determinat sa protejeze informatiile personale impotriva oricarei
pierderi, utilizari abuzive, dezvaluiri, modificari, indisponibilizari, acces
neautorizat si distrugere si isi ia toate masurile rezonabile pentru a asigura
confidentialitatea informatiilor personale, inclusiv prin utilizarea unor
masuri adecvate de natura organizationala si tehnica.</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Organizatorul
se obliga sa implementeze masuri tehnice si organizatorice adecvate in vederea asigurarii
unui nivel de securitate corespunzator datelor cu caracter personal apartinand participantilor
la Campanie. Organizatorul se obliga sa impuna oricaror imputerniciti obligatii
similare. La evaluarea nivelului adecvat de securitate se va tine seama in
special de riscurile prezentate de prelucrarea datelor cu caracter personal,
generate in special, in mod accidental sau ilegal, de distrugerea, pierderea,
modificarea, divulgarea neautorizata sau accesul neautorizat la datele cu
caracter personal transmise, stocate sau prelucrate.</span></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">In
procesul de furnizare a datelor cu caracter personal catre Organizator<s>,</s>
informatiile personale pot fi transferate de catre persoanele vizate prin
internet. Desi Organizatorul depune eforturi sustinute pentru a proteja
informatiile personale ce ii sunt puse la dispozitie, schimbul de informatii
prin intermediul unei conexiuni la internet nu este in totalitate sigur. Prin
urmare, nu putem garanta securitatea informatiilor personale transmise prin
internet. Odata primite aceste informatii, Operatorul va utiliza proceduri
stricte si metode de securitate pentru a impiedica accesul neautorizat la
acestea.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">9. Modificarea informarii privind prelucrarea datelor cu
caracter personale</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Organizatorul
are dreptul de a modifica prezenta Anexa la Regulamentul Oficial oricand pe
durata desfasurarii Campaniei, numai in cazul in care se descopera masuri mai
eficiente pentru protejarea si securizarea datelor cu caracter personal ale
persoanelor vizate si fara a afecta drepturile si libertatile acestora. Orice
astfel de modificare va fi publicata cu 24 de ore in prealabil pe site-ul Campaniei,
respectiv va fi adusa la cunostinta Participantilor prin aceleasi mijloace prin
care au fost incunostintate cu privire la Regulamentul Oficial al Campaniei.</span></p><p class="MsoNormal" style="text-align:justify"><b><span lang="RO" style="font-size:10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;
mso-ansi-language:RO">10. Alte prevederi</span></b></p><p class="MsoNormal" style="text-align:justify"><span lang="RO" style="font-size:
10.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-ansi-language:RO">Datele
personale ale Participantilor la Campanie vor fi procesate in conformitate cu
prevederile Regulamentului European nr. 679/2016 privind protectia persoanelor
fizice in ceea ce priveste prelucrarea datelor cu caracter personal si privind
libera circulatie a acestor date</span></p><p>
</p><p class="MsoNormal"></p>
  </div>
</div>
</template>
<script>
export default {
  data () {
    return {
      validation: {}
    }
  }
}
</script>

<style scoped>
  .text-rules{
    margin-right: 5%;
    margin-left: 5%;
    padding-top: 8%;
    color: black;
    background-color: white;
    border: 1px solid #a8a8a8;
    border-radius: .5em;
     /* width: 100%; */
    /* margin-bottom: 5%; */

  }
  .text-rules p {
    margin-top: 3%;
    margin-right: 5%;
    padding-left: 5%;
  }
  .home-rules{
    background-image: url('../assets/background.png');
    width: 100%;
    
  }
  .MsoTableGrid{
    width: 652px;
  }
  @media only screen and (max-width: 998px) {
    .text-rules{
    padding-top: 10%;
    }
  }
  @media only screen and (max-width: 500px) {
    .text-rules{
    padding-top: 19%;
    }
    .MsoTableGrid{
      width: 300px;
    }
  }
  @media only screen and (max-width: 400px) {
    .text-rules{
    padding-top: 23%;
    }
    .MsoTableGrid{
      width: 300px;
    }
  }
</style>
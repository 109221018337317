import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Form from '../components/Form.vue'
import Form2 from '../components/Form2.vue'
import Rules from '../components/Rules.vue'
import Winners from '../components/Winners.vue'
import Contact from '../components/Contact.vue'
import Home from '../components/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/form2',
    name: 'Formular',
    component: Form
  },
  {
    path: '/form',
    name: 'Formular',
    component: Form2
  },
  {
    path: '/rules',
    name: 'Regulament',
    component: Rules
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/winners',
    name: 'Castigatori',
    component: Winners
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/',
    name: 'Acasa',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

export default router
